export const popUp = () => {
  return {
    type: "POPUP",
  };
};

export const popUpFalse = () => {
  return {
    type: "POPUPFALSE",
  };
};
